export const bike = {
  lessThenFiveYears: {
    A: {
      "cc<75": {
        value: 1.708,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.708,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.793,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 1.879,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
    B: {
      "cc<75": {
        value: 1.676,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.676,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.76,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 1.844,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
  },
  lessThenTenYears: {
    A: {
      "cc<75": {
        value: 1.793,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.793,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.883,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 1.973,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
    B: {
      "cc<75": {
        value: 1.76,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.76,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.848,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 1.936,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
  },
  greaterThenTenYears: {
    A: {
      "cc<75": {
        value: 1.836,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.836,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.928,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 2.02,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
    B: {
      "cc<75": {
        value: 1.802,
        oneYearTp: 538,
        fiveYearTp: 2901,
      },
      "cc>75": {
        value: 1.802,
        oneYearTp: 714,
        fiveYearTp: 3851,
      },
      "cc<350": {
        value: 1.892,
        oneYearTp: 1366,
        fiveYearTp: 7365,
      },
      "cc>350": {
        value: 1.982,
        oneYearTp: 2804,
        fiveYearTp: 15117,
      },
    },
  },
  accessoriespercent: 4,
  noClaimBonus: 4,
  PAunnamedPercent: 0.14,
  premiumName: "Two Wheeler Premium",
  tppd: -50,
};

export const privateCar = {
  lessThenFiveYears: {
    A: {
      "cc=1000": {
        value: 3.127,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.283,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.44,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
    B: {
      "cc=1000": {
        value: 3.039,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.191,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.343,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
  },
  lessThenTenYears: {
    A: {
      "cc=1000": {
        value: 3.283,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.447,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.612,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
    B: {
      "cc=1000": {
        value: 3.191,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.351,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.51,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
  },
  greaterThenTenYears: {
    A: {
      "cc=1000": {
        value: 3.362,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.529,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.698,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
    B: {
      "cc=1000": {
        value: 3.267,
        oneYearTp: 2094,
        threeYearTp: 6521,
      },
      "cc<1500": {
        value: 3.43,
        oneYearTp: 3416,
        threeYearTp: 10640,
      },
      "cc>1500": {
        value: 3.596,
        oneYearTp: 7897,
        threeYearTp: 24596,
      },
    },
  },
  accessoriespercent: 4,
  noClaimBonus: 4,
  PAunnamedPercent: 0.05,
  premiumName: "Private Car premium",
};

export const taxi = {
  lessThenFiveYears: {
    A: {
      "cc=1000": {
        value: 3.284,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.448,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.612,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
    B: {
      "cc=1000": {
        value: 3.191,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.351,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.51,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
  },
  lessThenSevenYears: {
    A: {
      "cc=1000": {
        value: 3.366,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.534,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.703,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
    B: {
      "cc=1000": {
        value: 3.271,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.435,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.598,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
  },
  greaterThenSevenYears: {
    A: {
      "cc=1000": {
        value: 3.448,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.62,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.793,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
    B: {
      "cc=1000": {
        value: 3.351,
        tpPerm: 6040,
        perPassenger: 1162,
      },
      "cc<1500": {
        value: 3.519,
        tpPerm: 7940,
        perPassenger: 978,
      },
      "cc>1500": {
        value: 3.686,
        tpPerm: 10523,
        perPassenger: 1117,
      },
    },
  },
  accessoriespercent: 4,
  noClaimBonus: 4,
  PAunnamedPercent: 0.05,
  premiumName: "Taxi premium",
};

export const bus = {
  lessThenFiveYears: {
    A: 1.68,
    B: 1.672,
    C: 1.656,
  },
  lessThenSevenYears: {
    A: 1.722,
    B: 1.714,
    C: 1.697,
  },
  greaterThenSevenYears: {
    A: 1.764,
    B: 1.756,
    C: 1.739,
  },
  seatingCapacity: {
    "<=18": 350,
    "<=36": 450,
    "<=60": 550,
    ">60": 80,
  },
  educationalBus: {
    TP: 12192,
    perPassenger: 745,
    // TpPerpassenger:745
  },
  otherBus: {
    TP: 12192,
    perPassenger: 877,
  },
  iMT23: 15,
  accessoriespercent: 4,
  noClaimBonus: 4,
  PAunnamedPercent: 0.05,
  premiumName: "Bus Premium",
};

export const gcvG3 = {
  lessThenFiveYears: {
    A: 1.751,
    B: 1.743,
    C: 1.726,
  },
  lessThenSevenYears: {
    A: 1.795,
    B: 1.787,
    C: 1.77,
  },
  greaterThenSevenYears: {
    A: 1.839,
    B: 1.83,
    C: 1.812,
  },
  TP: {
    "<7500": 16049,
    "<12000": 27186,
    "<20000": 35313,
    "<40000": 43950,
    ">40000": 44242,
  },
  iMT23: 15,
  accessoriespercent: 4,
  noClaimBonus: 4,
  PAunnamedPercent: 0.05,
  premiumName: "Goods Carrying Vechicles",
};

export const threeWheeler = {
  gcv: {
    lessThenFiveYears: {
      A: 1.664,
      B: 1.656,
      C: 1.64,
    },
    lessThenSevenYears: {
      A: 1.706,
      B: 1.697,
      C: 1.681,
    },
    greaterThenSevenYears: {
      A: 1.747,
      B: 1.739,
      C: 1.722,
    },
    TP: 4492,
    tppd: -150,
    perPassenger: 0,
    accessoriespercent: 4,
    iMT23: 15,
    noClaimBonus: 4,
    PAunnamedPercent: 0.05,
    premiumName: "Three Wheeler Premium",
  },
  pcv: {
    lessThenFiveYears: {
      A: 1.278,
      B: 1.272,
      C: 1.26,
    },
    lessThenSevenYears: {
      A: 1.31,
      B: 1.304,
      C: 1.292,
    },
    greaterThenSevenYears: {
      A: 1.342,
      B: 1.336,
      C: 1.323,
    },
    TP: 2539,
    tppd: -150,
    perPassenger: 1214,
    accessoriespercent: 4,
    noClaimBonus: 4,
    iMT23: 15,
    PAunnamedPercent: 0.05,
    premiumName: "Three Wheeler Premium(PCV)",
  },
};

export const miscellaneousVehicle = {
  lessThenFiveYears: {
    A: 1.221,
    B: 1.202,
    C: 1.19,
  },
  lessThenSevenYears: {
    A: 1.238,
    B: 1.232,
    C: 1.22,
  },
  greaterThenSevenYears: {
    A: 1.268,
    B: 1.262,
    C: 1.25,
  },
  TP: 7267,
  tppd: -150,
  perPassenger: 0,
  accessoriespercent: 4,
  iMT23: 15,
  noClaimBonus: 4,
  PAunnamedPercent: 0.05,
  premiumName: "Miscellaneous Vehicle",
};
