const Menu = [
  // {
  //   _id: "Policy Details",
  //   Submenu: [
  //     // {
  //     //   header: "Policy Date",
  //     //   key: "formateIssueDate",
  //     // },
  //     {
  //       header: "Booking Code",
  //       key: "bookingCode",
  //       id: "Policy Details",
  //     },
  //     {
  //       header: "Policy Type",
  //       key: "policyType",
  //       id: "Policy Details",
  //     },
  //     {
  //       header: "Product",
  //       key: "product",
  //       id: "Policy Details",
  //     },
  //     // {
  //     //   header: "Payment Mode",
  //     //   key: "paymentMode",
  //     // },
  //   ],
  // },
  {
    _id: "Customer Details",
    Submenu: [
      {
        header: "Customer Name",
        key: "customerName",
        id: "Customer Details", //Changes by Arun
      },
      {
        header: "Mobile",
        key: "mobileNumber",
        id: "Customer Details", //Changes by Arun
      },
      {
        header: "Email",
        key: "email",
        id: "Customer Details", //Changes by Arun
      },
    ],
  },
  {
    _id: "Vehicle Details",
    Submenu: [
      {
        header: "RTO",
        key: "rtoId",
        id: "Vehicle Details", //Changes by sankarNagarajan
      },
      {
        header: "Reg Number",
        key: "registrationNumber",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "Vechicle Make",
        key: "vehicleMake",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "Model",
        key: "model",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "Reg Year",
        key: "registrationYear",
        id: "Vehicle Details", //Changes by Arun
      },

      {
        header: "GVW",
        key: "gvw",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "CC",
        key: "cc",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "Seat Capacity",
        key: "seatingCapacity",
        id: "Vehicle Details", //Changes by Arun
      },
      {
        header: "Fuel Type",
        key: "fuelType",
        id: "Vehicle Details", //Changes by Arun
      },
    ],
  },
  {
    _id: "Premium Details",
    Submenu: [
      {
        header: "PA Cover",
        key: "paCover",
        id: "Premium Details", //Changes by Arun
      },
      {
        header: "OD Discount",
        key: "odDisc",
        id: "Premium Details", //Changes by Arun
      },
      {
        header: "OD Premium",
        key: "odPremium",
        id: "Premium Details", //Changes by Arun
      },
      {
        header: "TP Premium",
        key: "tpPremium",
        id: "Premium Details", //Changes by Arun
      },
      {
        header: "Net Premium",
        key: "netPremium",
        id: "Premium Details", //Changes by Arun
      },
      {
        header: "Total Premium",
        key: "totalPremium",
        id: "Premium Details", //Changes by Arun
      },
    ],
  },
  {
    _id: "Policy Period",
    Submenu: [
      {
        header: "OD Start Date",
        key: "formateOdPolicyStartDate",
        id: "Policy Period", // Changes by Arun
      },
      {
        header: "OD Period",
        key: "odPolicyPeriod",
        id: "Policy Period", // Changes by Arun
      },
      {
        header: "OD End Date",
        key: "formateOdPolicyEndDate",
        id: "Policy Period", // Changes by Arun
      },
      {
        header: "TP Start Date",
        key: "formateTpPolicyStartDate",
        id: "Policy Period", // Changes by Arun
      },
      {
        header: "TP Period",
        key: "tpPolicyPeriod",
        id: "Policy Period", // Changes by Arun
      },
      {
        header: "TP End Date",
        key: "formateTpPolicyEndDate",
        id: "Policy Period", // Changes by Arun
      },
    ],
  },
  {
    _id: "User Payable",
    Submenu: [
      {
        header: "OD %",
        key: "payODPer",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "OD Amt",
        key: "payODAmount",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "TP %",
        key: "payTPPer",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "TP Amt",
        key: "payTPAmount",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "Net %",
        key: "payNetPer",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "Net Amt",
        key: "payNetAmount",
        id: "User Payable", //Changes by Arun
      },
      {
        header: "Total Payable Amount",
        key: "totalPayableAmount",
        id: "User Payable", //Changes by Arun
      },
    ],
  },
  {
    _id: "Branch Payable",
    Submenu: [
      {
        header: "OD %",
        key: "branchPayODPer",
        id: "Branch Payable", //Changes by Arun
      },
      {
        header: "OD Amt",
        key: "branchPayODAmount",
        id: "Branch Payable", //Changes by Arun
      },
      {
        header: "TP %",
        key: "branchPayTPPer",
        id: "Branch Payable", //Changes by Arun
      },
      {
        header: "TP Amt",
        key: "branchPayTPAmount",
        id: "Branch Payable", //Changes by Arun
      },
      {
        header: "Net %",
        key: "branchPayNetPer",
        id: "Branch Payable", //Changes by Arun
      },
      {
        header: "Net Amt",
        key: "branchPayNetAmount",
        id: "Branch Payable", //Changes by Arun
      },
      // {
      //   header: "Total Payable Amount",
      //   key: "totalPayableAmount",
      //   id: "User Payable", //Changes by Arun
      // },
    ],
  },
  {
    _id: "Receivable",
    Submenu: [
      {
        header: "OD %",
        key: "recODPer",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "OD Amt",
        key: "recODAmount",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "TP %",
        key: "recTPPer",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "TP Amt",
        key: "recTPAmount",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "Net %",
        key: "recNetPer",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "Net Amt",
        key: "recNetAmount",
        id: "Receivable", //Changes by Arun
      },
      {
        header: "Total Receivable Amount",
        key: "totalReceivableAmount",
        id: "Receivable", //Changes by Arun
      },
    ],
  },
  {
    _id: "Entry Details",
    Submenu: [
      {
        header: "Create Policy Name",
        key: "createdBy",
        id: "Entry Details", //Changes by Arun
      },
      {
        header: "Entry Pending Name",
        key: "verifyBy",
        id: "Entry Details", //Changes by Arun
      },
      {
        header: "Approved by Name",
        key: "approveBy",
        id: "Entry Details", //Changes by Arun
      },
      {
        header: "CC Entry Name",
        key: "ccEntryBy",
        id: "Entry Details", //Changes by Arun
      },
      {
        header: "Ticket Number",
        key: "ticketNumber",
        id: "Entry Details", //Changes by Arun
      },
    ],
  },
  {
    _id: "Payment Details",
    Submenu: [
      {
        header: "Payment Mode",
        key: "paymentMode",
        id: "Payment Mode", //Changes by Arun
      },
    ],
  },
];

export default Menu;
